<template>
  <div class="flex">
    <div
      class="bg-[#F2F3F8] mr-4 w-9 h-9 rounded-full flex items-center justify-center"
    >
      <img src="@src/assets/img/chat_bot/cyrus-icon-blue.svg" alt="robot" class="w-[20px] h-[20px]" />
    </div>
    <div
      class="p-4 bg-[#F2F3F8] rounded-e-2xl self-end max-w-[75%] mr-auto mb-4 relative"
      style="border-radius: 0 16px 16px 16px"
    >
      <HtmlRenderer
        tag="span"
        class="text-sm text-left ai-chat-message"
        :html-content="formatText(messageContent)"
      />
      <div v-if="messageImages.length > 0" class="flex flex-wrap mt-2 gap-2">
        <div v-for="(image, index) in messageImages" :key="image.image_url?.url || image" class="flex-shrink-0">
          <img
            :src="image.image_url?.url || image"
            alt="message image"
            class="h-20 w-20 object-cover rounded-lg cursor-pointer hover:opacity-90"
            @click="handleImageClick(index)"
          >
        </div>
      </div>
      <div class="flex justify-between mt-3 items-center">
        <span class="text-xs text-[#595C5F]"
          >{{ calculateTextLength.wordCount }} words /
          {{ calculateTextLength.charCount }} characters</span
        >
        <div class="flex justify-end items-center">
          <img
              v-if="isComposerModalOpen || props.type === 'blog'"
              v-tooltip="{ content: 'Replace the content in the editor' }"
              src="@src/assets/img/chat_bot/replace-content.svg"
              width="18"
              height="18"
              alt="external-link"
              class="ml-3 cursor-pointer active:opacity-50"
              @click.stop="replaceInEditor(messageContent)"
          />
          <img
              v-if="isComposerModalOpen || props.type === 'blog'"
              v-tooltip="{ content: 'Add to Editor' }"
              src="@src/assets/img/chat_bot/add-text.svg"
              width="18"
              height="18"
              alt="external-link"
              class="ml-3 cursor-pointer active:opacity-50"
              @click="addToEditor(messageContent)"
          />
          <img
            v-tooltip="{ content: 'Copy' }"
            src="@src/assets/img/chat_bot/copy.svg"
            class="ml-3 cursor-pointer active:opacity-50"
            width="18"
            height="18"
            alt="copy"
            @click="copyPrompt(messageContent)"
          />
          <div class="group relative">
            <img
              src="@src/assets/img/chat_bot/more-vertical.svg"
              width="18"
              height="18"
              alt="more-vertical.svg"
              class="ml-3 cursor-pointer active:opacity-50"
            />
            <!--  DROPDOWN-->
            <div
              class="w-[160px] flex-col p-4 absolute -right-3 rounded top-7 z-10 bg-white hidden group-hover:!flex"
              style="
                filter: drop-shadow(0px 3px 20px rgba(201, 207, 224, 0.4));
                border-radius: 10px;
              "
            >
              <img
                src="@src/assets/img/chat_bot/top-edge.svg"
                alt="arrow"
                class="absolute -top-2 -right-2"
              />
              <div class="cursor-pointer hover:font-bold" @click="props.deleteMessage(message._id)">
                <img
                  src="@src/assets/img/chat_bot/trash-2.svg"
                  alt="external-link"
                  width="18"
                  height="18"
                />
                <span class="text-[#D65151] text-sm ml-3">Delete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <VueEasyLightbox
      :visible="visible"
      :imgs="previewImages"
      :index="imageIndex"
      @hide="visible = false"
    />
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import { computed, onMounted, reactive, ref, defineProps, defineEmits } from 'vue'
import {useAiChatFormatter} from "@src/modules/AI-tools/composables/chat"
import HtmlRenderer from '@src/modules/common/components/htmlRenderer.vue'
import {useStore} from "@state/base"
import {useRoute} from "vue-router"
import {EventBus} from "@common/lib/event-bus"
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper"
import VueEasyLightbox from 'vue-easy-lightbox'

const {isComposerModalOpen} = useComposerHelper()

const props = defineProps({
  message: {
    type: Object,
    default: () => {},
  },
  deleteMessage:{
    type: Function,
    default: () => {},
  },
  type: {
    type: String,
    default: 'modal',
  },
})

// Lightbox state
const visible = ref(false)
const imageIndex = ref(0)

const { formatText } = useAiChatFormatter()

const messageContent = computed(() => {
  if (typeof props.message?.content === 'string') {
    return props.message.content
  } else if (Array.isArray(props.message?.content)) {
    return props.message.content
      .filter(item => item.type === 'text')
      .map(item => item.text)
      .join(' ')
  }
  return ''
})

const messageImages = computed(() => {
  if (Array.isArray(props.message?.content)) {
    return props.message.content.filter(item => item.type === 'image_url')
  }
  return []
})

const previewImages = computed(() => {
  return messageImages.value.map(image => image.image_url?.url || image)
})

const calculateTextLength = computed(() => {
  const content = messageContent.value
  const words = content.split(' ')
  return {
    wordCount: words.length,
    charCount: content.length,
  }
})

const copyPrompt = (prompt) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(prompt)
  }
}

const addToEditor = (prompt) => {
  let htmlText = prompt
  if(props.type === 'blog'){
    htmlText = formatText(prompt)
  }

  // below event is for blog editor implement for composer also
  EventBus.$emit('insert-chat-content', htmlText)
}

const replaceInEditor = (prompt) => {
  let htmlText = prompt
  if(props.type === 'blog'){
    htmlText = formatText(prompt)
  }
  // below event is for blog editor implement for composer also
  EventBus.$emit('replace-chat-content', htmlText)
}

const handleImageClick = (index) => {
  imageIndex.value = index
  visible.value = true
}
</script>

<style scoped>
</style>
