<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import {
  getPlatformName,
  pinterestProfileName,
  platformImage,
} from '@common/lib/integrations'
import { swalAttributes } from '@common/constants/common-attributes'
import { commonMethods } from '@common/store/common-methods'
import proxy from '@common/lib/http-common'
import {EventBus} from "@common/lib/event-bus";
import {
  instagram,
  social,
} from '@src/modules/integration/store/states/mutation-types'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import {
  bulkRemoveIntegrationURL,
  changeGroupPostedAsURL,
} from '@src/modules/integration/config/api-utils'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import TwitterIntegrationModal from '@src/modules/integration/components/dialogs/TwitterIntegrationModal.vue'
import { baseUrl, connectTwitterAccount } from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import useIntegrationComposable from '@src/modules/integration/Composables/useIntegrationComposable'
import AddFacebookGroup from '@modules/integration/components/dialogs/AddFacebookGroup.vue'
import CreateBoard from '@src/modules/integration/components/dialogs/CreateBoard.vue'
import NotificationDevices from '@src/modules/integration/components/dialogs/instagram/NotificationDevices.vue'
import RemoveDevice from '@src/modules/integration/components/dialogs/remove/RemoveDevice.vue'
import InstagramConnectModal from '@src/modules/integration/components/modals/InstagramConnectModal.vue'
import CstButton from '@ui/Button/CstButton.vue'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { mixin as clickaway } from 'vue3-click-away'
import CstInputFields from '@src/components/UI/Input/CstInputFields.vue'
import useDateFormat from '@common/composables/useDateFormat'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useBilling} from "@modules/billing/composables/useBilling";
import AddBluesky from "@modules/integration/components/dialogs/AddBluesky.vue";

export default {
  components: {
    AddBluesky,
    AddFacebookGroup,
    TwitterIntegrationModal,
    CstDropdownItem,
    RemoveDevice,
    NotificationDevices,
    CreateBoard,
    InstagramConnectModal,
    CstSimpleCheckbox,
    CstDropdown,
    CstButton,
    RecycleScroller,
    CstInputFields,
  },
  mixins: [ComposerHelper, clickaway],
  props: {
    type: {
      type: String,
      default: '',
    },
    externalLinkAccount: {
      type: Array,
      default: () => [],
    },
    isEasyConnectView: {
      type: Boolean,
      default: false,
    },
    isBulkReconnect: {
      type: Boolean,
      default: false,
    },
    connectTwitterContentStudio: {
      type: Boolean,
      default: false,
    },
    easyConnectToken: {
      type: String,
      default: '',
    },
  },
  emits: ['remove-account'],
  setup() {
    const { handleImageError } = useComposerHelper()
    const { momentWrapper } = useDateFormat()
    const {getAuthorizationUrl, getEasyConnectAuthUrl} = useIntegrationComposable()
    const {appData} = useWhiteLabelApplication()
    const {canAccess} = useFeatures()
    const queueAccess = canAccess('publishing_queues')
    const {showUpgradeModal} = useBilling()

    return {
      handleImageError,
      momentWrapper,
      appData,
      baseUrl,
      getAuthorizationUrl,
      getEasyConnectAuthUrl,
      queueAccess,
      showUpgradeModal,
    }
  },
  data() {
    return {
      follow_contentstudio: true,
      pinterest: {
        visible: 'board',
        profile: '',
      },
      commonMethods,
      device_id: '',
      fetch_slots_loader: false,
      facebookSdkReady: false,
      connectingIGBusiness: false,
      selectAll: false,
      selectedAccounts: [],
      bulkActions: {
        remove: 'remove',
        reconnect: 'reconnect',
      },
      twitterCustomApps: [],
      selectedFBGroup: null,
      openDropdowns: {},
      dropdownStyle: {
        top: '0px',
        left: '0px',
      },
      hideSearchBar: true,
      search: '',
      searchLoader: false,
      debounce: null,
      searchAccountItems: [],
      selectedBlueskyAccount: null,
    }
  },
  computed: {
    ...mapGetters([
      'getSocialAuthorization',
      'getSocialLoader',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getLinkedinAccounts',
      'getPinterestAccounts',
      'getTumblrAccounts',
      'getInstagramAccounts',
      'getGmbAccounts',
      'getThreadsAccounts',
      'getMobileDevices',
      'getWorkspaces',
      'getYoutubeAccounts',
      'getProfile',
      'getActiveWorkspaceAddonsLifetime',
      'getTiktokAccounts',
      'getActiveWorkspace',
      'getPlatformsList',
      'getPlan',
    ]),
    showSearchField() {
      return (
        !this.isEasyConnectView ||
        this.searchAccountItems?.length ||
        this.search
      )
    },
    scrollerHeight() {
      const itemCount = this.accountItems.length
      const maxItems = 10
      const itemHeight = 64
      const maxHeight = maxItems * itemHeight
      const heightOffset = 1 // needed to adjust offset pixels, otherwise will get a scrollbar

      return Math.min(itemCount * itemHeight, maxHeight) + heightOffset
    },
    listingHeading() {
      if (this.type === 'youtube') return 'YouTube'
      if (this.type === 'twitter') return 'X (Twitter)'

      return this.isGmbListing
        ? 'Google My Business'
        : this.type.charAt(0).toUpperCase() + this.type.slice(1)
    },
    // eslint-disable-next-line vue/return-in-computed-property
    connectionText() {
      if (this.isEasyConnectView) {
        return 'Connect'
      }
      switch (this.type) {
        case 'facebook':
          return 'Connect a new Facebook page'
        case 'linkedin':
          return 'Connect a new LinkedIn profile/page'
        case 'pinterest':
          return 'Connect a new Pinterest profile/board'
        case 'twitter':
        case 'tumblr':
        case 'gmb':
          return 'Connect a new ' + this.listingHeading + ' profile'
        case 'instagram':
          return 'Connect Instagram Profiles'
        case 'youtube':
          return 'Connect YouTube'
        case 'tiktok':
          return 'Connect TikTok'
        case 'threads':
          return 'Connect Threads'
      }
    },
    connectionLink() {
      // if (this.isInstagramListing) return true
      return this.getSocialAuthorization[this.type]
    },
    hasAccountItems() {
      if (this.externalLinkAccount.length > 0) {
        return true
      }
      return (
        !this.accountLoadingStatus &&
        this.accountItems &&
        this.accountItems.length
      )
    },
    pinterestBoardCount() {
      const accounts = []
      if (this.isEasyConnectView) {
        this.externalLinkAccount.forEach(function (account) {
          if (account.type === 'Board') {
            accounts.push(account)
          }
        })
        return accounts.length
      }
      this.getPinterestAccounts.items.forEach(function (account) {
        if (account.type === 'Board') {
          accounts.push(account)
        }
      })
      return accounts.length
    },
    pinterestProfileCount() {
      const accounts = []
      if (this.isEasyConnectView) {
        this.externalLinkAccount.forEach(function (account) {
          if (account.type === 'Profile') {
            accounts.push(account)
          }
        })
        return accounts.length
      }
      this.getPinterestAccounts.items.forEach(function (account) {
        if (account.type === 'Profile') {
          accounts.push(account)
        }
      })
      return accounts.length
    },
    accountItems() {
      if (this.isEasyConnectView) {
        if (this.type === 'pinterest') {
          const accounts = []
          // eslint-disable-next-line no-case-declarations
          const stateObject = this
          this.externalLinkAccount.forEach(function (account) {
            if (
              account.type ===
              stateObject.pinterest.visible.charAt(0).toUpperCase() +
                stateObject.pinterest.visible.slice(1)
            ) {
              delete account.policy
              accounts.push(account)
            }
          })
          return accounts
        }
        return this.externalLinkAccount.map(({ policy: _, ...rest }) => rest)
        // return this.externalLinkAccount
      }
      if (this.search) {
        if (this.type === 'pinterest') {
          const accounts = []
          const stateObject = this
          this.searchAccountItems.forEach(function (account) {
            if (
              account.type ===
              stateObject.pinterest.visible.charAt(0).toUpperCase() +
                stateObject.pinterest.visible.slice(1)
            ) {
              accounts.push(account)
            }
          })
          return accounts
        }
        return this.searchAccountItems
      }
      switch (this.type) {
        case 'pinterest':
          // eslint-disable-next-line no-case-declarations
          const accounts = []
          // eslint-disable-next-line no-case-declarations
          const stateObject = this
          this.getPinterestAccounts.items.forEach(function (account) {
            if (
              account.type ===
              stateObject.pinterest.visible.charAt(0).toUpperCase() +
                stateObject.pinterest.visible.slice(1)
            ) {
              accounts.push(account)
            }
          })
          return accounts
        default:
          return this[
          'get' +
          this.type.charAt(0).toUpperCase() +
          this.type.slice(1) +
          'Accounts'
              ].items
      }
    },
    renderAccountItems() {
      return this.accountItems.slice(0, this.viewableAccounts)
    },
    showAppInstallationWarning() {
      return this.isInstagramListing && this.getMobileDevices.length === 0
    },
    showQueueTime() {
      return this.type !== 'pinterest' || this.pinterest.visible === 'board'
    },
    showTokenStatus() {
      return [
        'facebook',
        'twitter',
        'linkedin',
        'pinterest',
        'instagram',
        'tiktok',
        'youtube',
        'gmb',
        'threads',
        'bluesky'
      ].includes(this.type)
    },
    isFacebookListing() {
      return this.type === 'facebook'
    },
    isTwitterListing() {
      return this.type === 'twitter'
    },
    isLinkedinListing() {
      return this.type === 'linkedin'
    },
    isPinterestListing() {
      return this.type === 'pinterest'
    },
    isTikTokListing() {
      return this.type === 'tiktok'
    },
    isYoutubeListing() {
      return this.type === 'youtube'
    },
    isPinterestBoardListing() {
      return this.isPinterestListing && this.pinterest.visible === 'board'
    },
    isPinterestProfileListing() {
      return this.isPinterestListing && this.pinterest.visible === 'profile'
    },
    isTumblrListing() {
      return this.type === 'tumblr'
    },
    isGmbListing() {
      return this.type === 'gmb'
    },
    isInstagramListing() {
      return this.type === 'instagram'
    },
    isThreadsListing() {
      return this.type === 'threads'
    },
    isBlueskyListing() {
      return this.type === 'bluesky'
    },
    canSaveSocialAccounts() {
      return this.hasPermission('can_save_social')
    },
    connectLinkVisibility() {
      return this.isEasyConnectView || this.canSaveSocialAccounts
    },
    accountLoadingStatus() {
      return this.getSocialLoader.fetch_accounts || this.searchLoader
    },
    /**
     * Checks if the bulk action dropdown show be shown
     * @returns {boolean}
     */
    showBulkEdit() {
      return this.selectedAccounts.length > 1
    },
    /**
     * List of accounts that can be bulk reconnected.
     * @returns {boolean}
     */
    canBulkReconnect() {
      return ['facebook', 'instagram', 'linkedin', 'gmb'].includes(this.type)
    },
    isAllowedToConnectTwitter() {
      return this.getPlan.subscription?.features?.twitter_cs_app
    },
    hasPinterestBoardOrProfile() {
      return this.pinterestBoardCount > 0 || this.pinterestProfileCount > 0
    },
    showViewMore() {
      return this.viewableAccounts < this.accountItems?.length
    },
    showLocation(){
      return this.isFacebookListing || this.isInstagramListing
    },
  },
  async mounted() {
    this.$nextTick(() => {
      if (this.type === 'pinterest') {
        if (this.pinterestProfileCount > 0 && this.pinterestBoardCount === 0) {
          this.pinterest.visible = 'profile'
        }
      }
    })
    if (this.isTwitterListing) {
      const { fetchTwitterCustomApps, twitterCustomApps } =
        useIntegrationComposable()
      await fetchTwitterCustomApps(this.isEasyConnectView)
      this.twitterCustomApps = twitterCustomApps
    }
  },
  beforeUnmount() {},
  methods: {
    ...mapActions(['searchPlatformsList']),
    isFacebookPage(account) {
      if(this.isFacebookListing) return this.accountType(account) === 'Page'
      return true
    },
    resetSearch() {
      this.search = ''
      this.closeSearchBar()
    },
    async searchAccount(value) {
      this.search = value
      if (this.search === '') {
        this.searchAccountItems = []
        return
      }
      this.searchLoader = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        const data = await this.searchPlatformsList({
          search: value,
          type: this.type,
        })
        this.searchAccountItems = data[this.type]
        this.searchLoader = false
      }, 500)
    },
    async openSearchBar() {
      this.hideSearchBar = false
      await this.$nextTick()
      this.$refs.searchBar.$el.focus()
    },
    closeSearchBar() {
      if (this.search === '') {
        this.searchAccountItems = []
        this.searchLoader = false
        this.hideSearchBar = true
      }
    },
    clearSearch() {
      this.search = ''
    },
    clickAway(accountId) {
      this.$set(this.openDropdowns, accountId, false)
    },
    /**
     * Toggles the dropdown visibility
     * @param event
     */
    toggleDropdown(accountId, event) {
      this.$set(this.openDropdowns, accountId, !this.openDropdowns[accountId])
      if (this.openDropdowns[accountId]) {
        this.$nextTick(() => {
          const button = event.target.closest('button')
          const rect = button.getBoundingClientRect()
          const dropdownWidth = 160

          this.$set(this.dropdownStyle, accountId, {
            top: `${rect.bottom + window.scrollY}px`,
            left: `${rect.right - dropdownWidth + window.scrollX}px`,
            minWidth: `${dropdownWidth}px`,
          })
        })
      }
    },
    editFbGroup(group) {
      this.selectedFBGroup = group
      this.$bvModal.show('add-fb-group-modal')
    },
    onPublishAccountChange(groupId, pageId, index) {
      console.debug('onPublishAccountChange', groupId, pageId)

      proxy
        .post(changeGroupPostedAsURL, {
          group_id: groupId,
          page_id: pageId,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        })
        .then((response) => {
          if (response.data.status === true) {
            this.getFacebookAccounts.items[index].posted_as =
              response.data.posted_as
            console.debug('onPublishAccountChange resp', response)
            this.alertMessage(
              'Default publish account for group changed!',
              'success'
            )
          }
        })
    },
    platformImage(platform, type) {
      return platformImage(platform, type)
    },
    pinterestProfileName(profileId) {
      return pinterestProfileName(
        this.getPinterestAccounts.all_items,
        profileId
      )
    },
    accountType(account) {
      if (this.isGmbListing) return 'Locations'
      if (this.isInstagramListing)
        return this.isIGPersonalAccount(account)
          ? 'Personal Profile'
          : 'Business Profile'
      return account.type ? _.startCase(account.type) : 'Profile'
    },
    accountName(account) {
      return getPlatformName(account)
    },
    getPinterestProfileName(account) {
      return account.username
    },
    // use getPlatformIdentifierValue
    accountConnectorId(account) {
      switch (this.type) {
        case 'facebook':
        case 'twitter':
        case 'linkedin':
        case 'instagram':
          return account[this.type + '_id']
        case 'pinterest':
          return account.board_id || account?.profile_id
        case 'youtube':
        case 'tiktok':
        case 'tumblr':
        case 'threads':
          return account.platform_identifier
        case 'gmb':
          return account.name
        default:
          return ''
      }
    },
    triggerBoardCreation(profile) {
      this.pinterest.profile = profile
      this.$bvModal.show('createBoard')
    },
    async fetchAccountQueueSlots(account) {
      console.log('Method::fetchAccountQueueSlots ~ account -> ', account)
      account.platform_type = this.type
      this.fetch_slots_loader = true
      this.$bvModal.show('account_queue_slot_modal')
      const response = await this.$store.dispatch(
        'fetchAccountQueueSlots',
        account
      )
      if (!response) {
        this.$bvModal.hide('account_queue_slot_modal')
      }
      this.fetch_slots_loader = false
    },
    async shuffleQueue(account) {
      this.$bvModal
        .msgBoxConfirm(
          'By performing this action, your existing scheduled posts belonging to this account queue will be shuffled.',
          {
            title: 'Shuffle Posts',
            ...swalAttributes(),
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch('shuffleQueuePosts', account)
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err)
        })
    },
    accountExpirationStates(account) {
      const platformExpirationKeys = {
        facebook: 'token_expires_at',
        instagram: 'token_expires_at',
        linkedin: 'refresh_token_expires_at',
        pinterest: 'refresh_token_expires_at',
        tiktok: 'refresh_expires_in',
        threads: 'token_expires_at',
      }

      if (account) {
        let expiryElement = ''

        if (!['twitter', 'youtube', 'tumblr', 'gmb'].includes(this.type)) {
          const dateObj = account[platformExpirationKeys[this.type]]
          const date = typeof dateObj === 'string' ? dateObj : dateObj?.date
          if (date && account.validity === 'valid') {
            expiryElement = `<span class="text-gray-700 text-xs">${this.momentWrapper(
              date
            ).formatDate()}</span>`
          }
        }
        if (commonMethods.isFacebookGroup(account)) {
          return `<span class="green">Valid</span>`
        }
        if (account.validity) {
          // returning status expired in case of invalid and expired validity
          if (
            account.validity === 'invalid' ||
            account.validity === 'expired'
          ) {
            return `<span class="red">Expired</span>${expiryElement}`
          }

          if (account.validity === 'limit_exceeded') {
            return '<span class="red">Daily Limit Reached</span>'
          }
        }

        if (account.days_remaining !== undefined) {
          // returning in case of remaining days are greater than 7
          if (account.days_remaining > 0) {
            if (!this.isLinkedinListing || account.days_remaining < 30) {
              return (
                '<span class="green">Expiring in ' +
                account.days_remaining +
                ' days</span> ' +
                expiryElement
              )
            }
          }
          return '<span class="green">Valid</span>' + expiryElement
        }

        if (account && account.expires_at) {
          // returning status expired in case of expired_at time passed
          if (this.utcTimePassingCheck(account.expires_at.date)) {
            return '<span class="red">Expired</span>' + expiryElement
          }

          const now = this.momentWrapper().utc().format('YYYY-MM-DD HH:mm')
          const days = this.checkTimeDifference(
            now,
            account.expires_at.date,
            'days'
          )

          // checking remaining time in case of expiring on today
          if (!this.isLinkedinListing && days === 0) {
            const hours = this.checkTimeDifference(
              account.expires_at.date,
              now,
              'hours'
            )
            if (hours === 0) {
              const minutes = this.checkTimeDifference(
                now,
                account.expires_at.date,
                'minutes'
              )
              if (minutes === 0) {
                return (
                  '<span class="yellow">Expiring in ' +
                  this.checkTimeDifference(
                    now,
                    account.expires_at.date,
                    'seconds'
                  ) +
                  ' seconds</span>' +
                  expiryElement
                )
              }
              return (
                '<span class="yellow">Expiring in ' +
                minutes +
                ' minutes</span>' +
                expiryElement
              )
            }
            return (
              '<span class="yellow">Expiring in ' +
              hours +
              ' hours</span>' +
              expiryElement
            )
          }

          // returning in case of remaining days less than 7
          if (days <= 7) {
            return (
              '<span class="yellow">Expiring in ' +
              days +
              ' days</span>' +
              expiryElement
            )
          }

          // returning in case of remaining days are greater than 7
          if (!this.isLinkedinListing || days < 30) {
            return (
              '<span class="green">Expiring in ' +
              days +
              ' days</span>' +
              expiryElement
            )
          }
        }
        return '<span class="green">Valid</span>' + expiryElement
      }
      return ''
    },
    limitExceededTooltip(account) {
      if (account && account.validity === 'limit_exceeded') {
        const name = this.accountName(account)
        return (
          'Your daily limit for access token against ' +
          name +
          ' has been reached. Please make sure to spread out the posts throughout the day to avoid reaching the daily rate limit.'
        )
      }
      return ''
    },
    showAccountNotificationDevices(account) {
      this.$store.commit(
        instagram.SET_NOTIFICATION_DEVICES,
        JSON.parse(JSON.stringify(account))
      )
      this.$bvModal.show('notification_devices')
    },
    enableDevicesCount(account) {
      let devices = this.getMobileDevices.length
      this.getMobileDevices.forEach(function (device) {
        if (
          (account.disabled_devices &&
            account.disabled_devices.includes(device.device_id) === true) ||
          device.status_code
        ) {
          devices = devices - 1
        }
      })
      return devices
    },
    getQueuedTimesCount(account) {
      let count = 0
      if (account.QueueSlots) {
        account.QueueSlots.forEach(function (slots) {
          if (slots && slots.status) count += slots.times.length
        })
      }
      return count + ' times per week'
    },
    accountIdentifier(account) {
      let key = ''
      switch (this.type) {
        case 'pinterest':
          key = account.board_id ? 'board_id' : 'pinterest_id'
          break
        case 'tumblr':
        case 'tiktok':
        case 'youtube':
        case 'threads':
        case 'bluesky':
          key = 'platform_identifier'
          break
        case 'gmb':
          key = 'name'
          break
        default:
          key = this.type.toLowerCase() + '_id'
      }
      return account[key]
    },
    removeAccount(account) {
      console.debug('Method::removeAccount', account)
      this.$parent.type = this.type
      this.$parent.platform = this.accountIdentifier(account)
      this.$bvModal.show('removeIntegration')
    },
    async connectAccount(process, account) {
      console.debug(
        'Method:connectAccount',
        process,
        account,
        this.connectionLink
      )
      if (this.isEasyConnectView) {
        const details = { process }
        this.getSelectedAccountConnectorIds(details, account)
        if (
          this.type === 'youtube' ||
          this.type === 'tiktok' ||
          this.type === 'instagram' ||
          this.type === 'threads'
        ) {
          details.type = this.type
        }
        if (this.type === 'twitter') details.follow = this.follow_contentstudio
        if (this.type === 'tumblr') {
          details.type = 'tumblr_social'
        }

        const baseUrl = window.location.href.split('#')[0]
        details.callback_url = `${baseUrl}#${this.type}`

        await this.getEasyConnectAuthUrl({
          platform: this.type === 'tumblr' ? 'tumblr_social' : this.type,
          connectionDetails: details,
        })
      } else {
        const details = { process }
        // takes in the details object and merges connector_id(string) or connector_ids(array) in case of bulk_reconnect.
        this.getSelectedAccountConnectorIds(details, account)
        if (this.isTwitterListing) details.follow = this.follow_contentstudio
        if (this.isTumblrListing) {
          details.type = 'tumblr_social'
        }
        if (
          this.isYoutubeListing ||
          this.isTikTokListing ||
          this.isInstagramListing ||
          this.isThreadsListing
        ) {
          details.type = this.type
        }

        const baseUrl = window.location.href.split('#')[0]
        details.callback_url = `${baseUrl}#${this.type}`

        await this.getAuthorizationUrl({
          platform: details.type || this.type,
          connectionDetails: details,
        })
      }
    },
    getSelectedAccountConnectorIds(details, account) {
      switch (details.process) {
        case 'reconnect':
          if (account) details.connector_id = this.accountConnectorId(account)
          break
        case 'bulk_reconnect':
          details.connector_ids = this.accountItems
            .filter((acc) => this.selectedAccounts.includes(acc._id))
            .map((acc) => this.accountConnectorId(acc))
          break
        default:
          console.log(
            'process is neither reconnect nor bulk_reconnect',
            details.process
          )
      }
    },
    removeInstagramExternalIntegration(platform, type) {
      console.log('Method::removeInstagramExternalIntegration', platform)

      // confirm removal request from the user
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to remove integration?', {
          title: 'Remove Integration',
          ...swalAttributes(),
        })
        .then((value) => {
          // if user confirm removal request
          if (value) {
            const integrations = platform.linked_to
            integrations[type] = false
            this.$store.dispatch('removeInstagramExternalIntegration', {
              integrations,
              platform_identifier: platform.instagram_id,
              workspace_id: platform.workspace_id,
            })
          }
        })
        .catch((err) => {
          // An error occurred
          console.error(err)
        })
    },
    /**
     * Click event handler for selecting all social accounts.
     * Selects all social accounts of particular platform type
     */
    onChangeSelectAll() {
      this.selectedAccounts = this.selectAll
        ? this.accountItems.map((account) => account._id)
        : []
    },
    /**
     * Click event handler for selecting a social account
     * Toggles select all checkbox value based on the accounts selected
     */
    onSelectSocialAccount() {
      this.selectAll = this.selectedAccounts.length === this.accountItems.length
    },
    /**
     * Click event handler for selecting between pinterest profile and boards.
     * Clears the selected accounts data and select all checkbox
     */
    togglePinterestBoardProfile() {
      this.selectedAccounts = []
      this.selectAll = false
    },
    /**
     * Bulk action handler for removing or reconnecting social accounts
     * @param action
     */
    bulkActionHandler(action) {
      switch (action) {
        case this.bulkActions.remove:
          this.bulkRemoveAccounts()
          break
        case this.bulkActions.reconnect:
          this.connectAccount('bulk_reconnect')
          break
        default:
      }
    },
    /**
     * Action for removing selected accounts
     * @returns {Promise<void>}
     */
    async bulkRemoveAccounts() {
      console.log('METHOD::bulkRemoveAccounts')
      const self = this
      const res = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete selected accounts?',
        {
          title: 'Remove Posts',
          ...swalAttributes(),
        }
      )
      if (res) {
        const payload = {
          workspace_id: self.getActiveWorkspace._id,
          channel_type: self.type,
          selected_accounts: self.selectedAccounts,
        }
        proxy.post(bulkRemoveIntegrationURL, payload).then(({ data }) => {
          const { status, message } = data
          if (status) {
            // on status: true; we have to remove the selectedAccounts from the PlatformsList(vuex) that is being used in composer and planner.
            this.$store.commit(
              social.SET_PLATFORM_LIST,
              this.getPlatformsList.filter(
                (account1) =>
                  !this.selectedAccounts.some(
                    (account2) => account1._id === account2
                  )
              )
            )
            // on status: true we have to remove the selectedAccounts from the account type's vuex store.
            const sanitizedChannelName =
              payload.channel_type.charAt(0).toUpperCase() +
              payload.channel_type.slice(1)
            const items = this[`get${sanitizedChannelName}Accounts`].items
            this[`get${sanitizedChannelName}Accounts`].items = items.filter(
              (account1) =>
                !this.selectedAccounts.some(
                  (account2) => account1._id === account2
                )
            )
          }
          this.alertMessage(message, status ? 'success' : 'error')
          this.selectedAccounts = []
        })
      }
    },
    /**
     * Click event handler for connecting instagram account
     * @param type type of account to connect
     */
    async connectIGAccount(type) {
      console.debug(
        'METHOD::connectIGAccount',
        type,
        this.getSocialAuthorization,
        this.getSocialAuthorization[type]
      )

      const baseUrl = window.location.href.split('#')[0]
      const details = {
        process: 'connect',
        callback_url: `${baseUrl}#${this.type}`,
      }

      if (this.isEasyConnectView) {
        await this.getEasyConnectAuthUrl({
          platform: this.type,
          connectionDetails: details,
        })
      } else {
        await this.getAuthorizationUrl({
          platform: this.type,
          connectionDetails: details,
        })
      }
    },
    /**
     * Get Instagram warning tooltips
     */
    getInstagramWarningMessage(isPersonalAccount) {
      let message = ''
      if (isPersonalAccount && this.showAppInstallationWarning)
        message = `1. Mobile app is not installed, so you'll not
              be able to use the push notification method. <a
                  href="https://docs.contentstudio.io/article/696-how-to-connect-instagram-to-contentstudio"></a> Learn
              more!
              <br/>
              2. Direct publishing is not supported on IG personal profiles and will be sent via Push Notification method instead.`
      else if (isPersonalAccount)
        message = `Direct publishing is not supported on IG personal profiles and will be sent via Push Notification method instead.`
      else if (this.showAppInstallationWarning)
        message = `Mobile app is not installed, so you'll not
              be able to use the push notification method. <a
                  href="https://docs.contentstudio.io/article/696-how-to-connect-instagram-to-contentstudio"></a> Learn
              more!`

      return message
    },

    /**
     * @description Connects a Twitter app to ContentStudio by redirecting to the app URL with the necessary query parameters
     * @param {Object} twitterAppData - The Twitter app data containing the app ID, URL, and platform type
     * @param process
     * @param connectorID
     */
    async connectTwitterApp(
      twitterAppData = {},
      process = 'connect',
      connectorID = null
    ) {
      // Define an object containing details of the connection process

      const baseUrl = window.location.href.split('#')[0]
      const details = {
        process,
        app_id: twitterAppData._id,
        follow: this.follow_contentstudio,
        callback_url: `${baseUrl}#${this.type}`,
      }

      // The ID of the connector being used for the connection process, defaults to null
      if (connectorID) details.connector_id = connectorID

      if (this.isEasyConnectView) {
        await this.getEasyConnectAuthUrl({
          platform: this.type,
          connectionDetails: details,
        })
      } else {
        await this.getAuthorizationUrl({
          platform: this.type,
          connectionDetails: details,
        })
      }
    },

    /**
     * @description Connects a custom Twitter app by making a GET API call to authorize the app and redirect back to the website.
     * This method is triggered when the user selects an existing app from the list of custom apps already connected to ContentStudio.
     * @param appID
     * @param process
     * @param connectorID
     * @returns {Promise<void>}
     */
    async connectTwitterCustomApp(
      appID = '',
      process = 'connect',
      connectorID = null
    ) {
      // Check if appID is provided, if not return
      if (!appID && this.twitterCustomApps?.length) {
        appID = this.twitterCustomApps[0]._id
      }

      try {
        // Make a GET API call to get the authorization URL for the Twitter app
        const { data } = await proxy.post(connectTwitterAccount, {
          workspace_id: this.getActiveWorkspace._id,
          id: appID,
          connection_details: {
            process,
            connector_id: connectorID,
            follow: this.follow_contentstudio,
            callback_url: `${window.location.href.split('#')[0]}#twitter`,
          },
        })

        // If the API call is successful, connect the Twitter app by calling connectTwitterApp method
        if (data.status) {
          window.location.href = data?.url
        } else {
          // If the API call fails, display an error message using the toastNotification method
          await this.$store.dispatch('toastNotification', {
            message: data?.message || UNKNOWN_ERROR,
            type: 'error',
          })
        }
      } catch (error) {
        // If the API call fails, display an error message using the toastNotification method
        await this.$store.dispatch('toastNotification', {
          message:
            error.response?.data?.message || error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    },

    /**
     * @description Checks if the given account has a developer app ID associated with it.
     * @returns {boolean} - Returns true if the account has a developer app ID, otherwise false.
     * @param account
     */
    hasDeveloperAppId(account = {}) {
      return !!account?.developer_app_id
    },
    copyConnectionLink(link) {
      navigator.clipboard.writeText(link)
      this.$store.dispatch('toastNotification', {
        message: 'Link copied to clipboard',
        type: 'success',
      })
    },
    getSecondaryText() {
      switch (this.type) {
        case 'facebook':
          return '(Pages)'
        case 'instagram':
          return '(Business Accounts)'
        case 'linkedin':
          return '(Profiles & Pages)'
        case 'pinterest':
          return '(Profiles & Public Boards)'
        case 'twitter':
          return '(Profiles)'
        case 'youtube':
          return '(Channels)'
        case 'gmb':
          return '(Verified Locations)'
        case 'tiktok':
          return '(Profiles)'
        case 'tumblr':
          return '(Profiles)'
        case 'threads':
          return '(Profiles)'
        case 'bluesky':
          return '(Profiles)'
      }
    },
    onReconnectClick(account) {
      if(this.type === 'bluesky') {
        this.selectedBlueskyAccount = {
          id: account?._id || ''
        }
        this.$bvModal.show('add-bluesky-modal')
        return
      }

      if (this.connectLinkVisibility && this.isIGPersonalAccount(account)) {
        this.connectIGAccount('instagram_personal')
      } else if (this.isTwitterListing && this.hasDeveloperAppId(account)) {
        this.connectTwitterCustomApp(
          account.developer_app_id,
          'reconnect',
          account.twitter_id
        )
      } else if (
        (this.connectLinkVisibility &&
          (!this.isTwitterListing || this.isAllowedToConnectTwitter)) ||
        this.isEasyConnectView
      ) {
        this.connectAccount('reconnect', account)
      }
    },
    handleLocation(account){

      this.$bvModal.show('set-default-location-modal')

      EventBus.$emit('add-default-location', {platform_type: this.type, account_id: account._id, default_location: account.location?.name})

    },
  },
}
</script>

<template>
  <div :id="type" class="bg-white rounded-xl mb-7">
    <CreateBoard
      v-if="isPinterestListing"
      :board_profile="pinterest.profile"
    ></CreateBoard>
    <template v-if="isInstagramListing">
      <InstagramConnectModal></InstagramConnectModal>
      <NotificationDevices></NotificationDevices>
      <RemoveDevice :device_id="device_id"></RemoveDevice>
    </template>
    <div class="flex px-4 py-4 items-center justify-between">
      <div class="flex items-center">
        <div
          :class="
            'inline-flex justify-center items-center w-10 h-10 rounded-lg ' +
            getSocialBackground(type)
          "
        >
          <img
            :src="require('@assets/img/integration/' + getSocialIcon(type))"
            alt="Platform Icon"
            class="h-[20px]"
          />
        </div>
        <span class="mx-2 font-medium text-lg">{{ listingHeading }}</span>

        <span
          v-if="isEasyConnectView"
          class="ml-1 font-medium text-lg text-[#757A8A]"
        >
          {{ getSecondaryText() }}
        </span>
        <img
          v-if="type === 'bluesky'"
          src="@src/assets/img/common/new_tag.svg"
          alt="new-tag"
          class="ml-1"
        />
        <template v-if="showSearchField">
          <i
            v-if="hideSearchBar"
            v-tooltip.right="'Search'"
            aria-hidden="true"
            class="fa fa-search fa-lg text-gray-700 cursor-pointer hover:text-blue-500 w-10 h-10 flex justify-center items-center rounded-lg"
            @click="openSearchBar"
          ></i>
          <div v-else class="relative w-[15.5rem]" style="transition: all 0.6s">
            <CstInputFields
              ref="searchBar"
              :value="search"
              class="ml-3 pr-16 plannerSearchBar"
              placeholder="Search your social account..."
              size="small"
              type="text"
              @change="searchAccount"
              @blur="closeSearchBar"
            />
            <span v-if="!searchLoader && search.length">
              <i
                aria-hidden="true"
                class="fa fa-times absolute right-11 top-1/3 bg-white text-gray-700 cursor-pointer hover:text-blue-500"
                @click="clearSearch"
              ></i>
            </span>
            <span v-if="!searchLoader">
              <i
                aria-hidden="true"
                class="fa fa-search absolute right-4 top-1/3 bg-white text-gray-700 cursor-pointer hover:text-blue-500"
              ></i>
            </span>
          </div>
        </template>
        <!--BulkAction::start-->
        <div v-if="showBulkEdit" class="mx-3 w-[11rem]">
          <CstDropdown class="w-full">
            <template v-slot:selected>
              <span> <i class="far fa-layer-group mr-2"></i>Bulk Actions</span>
            </template>
            <template v-slot>
              <CstDropdownItem
                v-if="!isEasyConnectView"
                @click="bulkActionHandler(bulkActions.remove)"
                >Remove Selected
              </CstDropdownItem>
              <CstDropdownItem
                v-if="canBulkReconnect || isEasyConnectView"
                @click="bulkActionHandler(bulkActions.reconnect)"
                >Reconnect Accounts
              </CstDropdownItem>
            </template>
          </CstDropdown>
        </div>
        <!--BulkAction::end-->
      </div>
      <div class="head_right ml-auto">
        <clip-loader
          v-if="isInstagramListing && connectingIGBusiness"
          class="d-inline-block align-middle"
          style="margin-right: 10px"
          color="#989eb5"
          :size="'25px'"
        />

        <template v-if="connectLinkVisibility || isTwitterListing">
          <div v-if="isTwitterListing || type === 'twitter'">
            <TwitterIntegrationModal :external-link="isEasyConnectView" />
            <div class="field_group inline-block">
              <div class="checkbox_container">
                <label for="follow-content-studio">
                  Follow ContentStudio
                  <input
                    id="follow-content-studio"
                    v-model="follow_contentstudio"
                    name="option2"
                    type="checkbox"
                  />
                  <span class="check"></span>
                </label>
              </div>
            </div>
            <a
              v-if="
                !twitterCustomApps.length &&
                !isAllowedToConnectTwitter &&
                !isEasyConnectView
              "
              class="btn btn-studio-theme-space"
              @click="$bvModal.show('add-twitter-account-modal')"
              ><span>{{ connectionText }}</span></a
            >
            <CstDropdown
              v-else
              button-classes="bg-cs-primary hover:bg-cs-primary text-white"
              :dropdown-classes="isEasyConnectView ? '!right-[23%]' : ''"
              container-classes="!w-auto"
              :parent-position-relative="false"
            >
              <template v-slot:selected>
                <span>{{
                  isEasyConnectView ? 'Connect' : 'Connect X (Twitter) Profile'
                }}</span>
              </template>
              <template v-slot>
                <CstDropdownItem
                  v-if="
                    (isAllowedToConnectTwitter && connectLinkVisibility) ||
                    connectTwitterContentStudio
                  "
                  @click="connectAccount('connect')"
                >
                  <div class="w-full flex items-center gap-x-2">
                    <img
                      src="@src/assets/img/integration/cs_logo.svg"
                      class="w-6 h-6"
                      alt=""
                    />
                    <p class="text-[#3C4549] text-sm"
                      >Connect with Developer App</p
                    >
                  </div>
                </CstDropdownItem>
                <CstDropdownItem
                  v-if="twitterCustomApps.length"
                  class="select-none cursor-default"
                >
                  <p class="text-sm text-[#3C4549] font-medium">Custom Apps</p>
                </CstDropdownItem>
                <template v-if="twitterCustomApps.length">
                  <CstDropdownItem
                    v-for="customApp in twitterCustomApps"
                    :key="`custom_app_${customApp._id}`"
                    @click="connectTwitterCustomApp(customApp._id)"
                  >
                    <p class="text-sm text-[#3C4549] max-w-[228px] truncate">{{
                      customApp.app_name
                    }}</p>
                  </CstDropdownItem>
                </template>
                <CstDropdownItem
                  @click="$bvModal.show('add-twitter-account-modal')"
                >
                  <button
                    class="flex justify-center items-center py-3 px-12 border-0 white-space-nowrap text-sm font-medium rounded-lg text-white text-center bg-[#2961D2] hover:bg-blue-500 h-11"
                  >
                    <span class="py-[14px] text-sm">Add New Custom App</span>
                  </button>
                </CstDropdownItem>
              </template>
            </CstDropdown>
          </div>

          <div v-else-if="type === 'facebook'">
            <template v-if="!isEasyConnectView">
              <AddFacebookGroup
                :group="selectedFBGroup"
                :action="selectedFBGroup ? 'Update' : 'Add'"
                @close="selectedFBGroup = null"
              />
              <a
                  class="mr-3 btn btn-studio-theme-space"
                  :class="{ 'w-[100px] justify-center': isEasyConnectView }"
                  @click="$bvModal.show('add-fb-group-modal')"
              >
                <span>Add Facebook Group</span>
              </a>
            </template>
            <a
              class="btn btn-studio-theme-space"
              :class="{ 'w-[100px] justify-center': isEasyConnectView }"
              @click.prevent="connectAccount('connect')"
            >
              <span>{{ connectionText }}</span>
            </a>
          </div>

          <div v-else-if="type === 'bluesky'">
            <template>
              <AddBluesky
                  :account="selectedBlueskyAccount"
                  :action="selectedBlueskyAccount ? 'Reconnect' : 'Connect'"
                  :token="easyConnectToken"
                  @close="selectedBlueskyAccount = null"
              />
              <a
                  class="mr-3 btn btn-studio-theme-space"
                  :class="{ 'w-[100px] justify-center': isEasyConnectView }"
                  @click="$bvModal.show('add-bluesky-modal')"
              >
                <span>{{!isEasyConnectView ? 'Connect Bluesky Profile' : 'Connect'}}</span>
              </a>
            </template>
          </div>

          <a
            v-else-if="
              isEasyConnectView && (type === 'youtube' || type === 'gmb')
            "
            class="btn btn-studio-theme-space"
            :class="{ 'w-[100px] justify-center': isEasyConnectView }"
            @click.prevent="connectAccount('connect')"
          >
            <span>{{ connectionText }}</span>
          </a>

          <a
            v-else-if="type === 'youtube' || type === 'gmb'"
            class="my-3 cursor-pointer"
            @click.prevent="connectAccount('connect')"
          >
            <img
              src="@assets/img/btn_google_signin.png"
              alt="Sign in button"
              width="170"
            />
          </a>

          <!--  Instagram Dropdown start       -->
          <div v-else-if="type === 'instagram' && featureFlag()">
            <CstDropdown
              button-classes="bg-cs-primary hover:bg-cs-primary text-white"
            >
              <template v-slot:selected>
                <span>{{ connectionText }}</span>
              </template>
              <template v-slot>
                <CstDropdownItem @click="connectIGAccount('instagram')"
                  >Business Account
                </CstDropdownItem>
                <CstDropdownItem @click="connectIGAccount('instagram_personal')"
                  >Personal Account
                </CstDropdownItem>
              </template>
            </CstDropdown>
          </div>
          <!--  Instagram Dropdown End       -->
          <a
            v-else-if="type === 'instagram' && isEasyConnectView"
            v-tooltip="'Connect Instagram Business Account'"
            class="btn btn-studio-theme-space"
            :class="{ 'w-[100px] justify-center': isEasyConnectView }"
            @click.prevent="connectIGAccount('instagram')"
            ><span>{{ connectionText }}</span></a
          >
          <a
            v-else
            class="btn btn-studio-theme-space"
            :class="{ 'w-[100px] justify-center': isEasyConnectView }"
            @click.prevent="connectAccount('connect')"
            ><span>{{ connectionText }}</span></a
          >
        </template>
      </div>
    </div>
    <div>
      <div
        v-if="
          isPinterestListing && (hasAccountItems || hasPinterestBoardOrProfile)
        "
        class="flex items-center py-2 px-6"
      >
        <div class="flex basic_form items-center">
          <div class="field_group">
            <label class="radio_container">
              <input
                id="pin_board"
                v-model="pinterest.visible"
                name="profile_board_toggle"
                type="radio"
                value="board"
                @change="togglePinterestBoardProfile"
              />
              <div>
                Show Boards ({{ pinterestBoardCount }})
                <span class="check"></span>
              </div>
            </label>
          </div>
          <div class="field_group">
            <label class="radio_container">
              <input
                id="pin_profile"
                v-model="pinterest.visible"
                name="profile_board_toggle"
                type="radio"
                value="profile"
                @change="togglePinterestBoardProfile"
              />
              <div>
                Show Profiles ({{ pinterestProfileCount }})
                <span class="check"></span>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="w-full rounded-xl px-4 pb-2">
        <table class="w-full p-2 table-fixed">
          <thead
            v-if="hasAccountItems"
            class="focus:outline-none h-16 bg-gray-50"
          >
            <tr>
              <th
                v-if="isBulkReconnect || !isEasyConnectView"
                class="text-center w-[3.125rem]"
              >
                <CstSimpleCheckbox
                  v-if="isBulkReconnect"
                  v-model="selectAll"
                  @change="onChangeSelectAll"
                ></CstSimpleCheckbox>
                <CstSimpleCheckbox
                  v-if="!isEasyConnectView"
                  v-model="selectAll"
                  @change="onChangeSelectAll"
                ></CstSimpleCheckbox>
              </th>
              <th class="pl-3 max-w-[25%] w-[25%]">Name</th>
              <th v-if="showLocation && !isEasyConnectView" class="text-center">Location</th>
              <th
                v-if="isPinterestBoardListing && !isEasyConnectView"
                class="text-center"
            >Profile
            </th>
            <th v-if="showQueueTime && !isEasyConnectView" class="text-center"
            >Queue Time
            </th>
            <th v-if="!isEasyConnectView" class="text-center">Connected Via</th>
            <th v-if="isTwitterListing" class="text-center">Connected App</th>
            <th v-if="isInstagramListing && !isEasyConnectView" class="text-center"
            >Notifications
            </th>
            <th v-if="showTokenStatus" class="text-center">Token Status</th>
            <th
                v-if="canSaveSocialAccounts || isEasyConnectView"
                class="w-[11.375rem] xl:w-[22rem]"
                :class="isEasyConnectView ? 'text-right pr-14' : 'text-center'"
                >Actions
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="accountLoadingStatus">
              <td colspan="4" class="text-center pb-2">
                <clip-loader color="#4165ed" :size="'12px'"></clip-loader>
              </td>
            </tr>
            <template v-if="!accountLoadingStatus && isEasyConnectView">
              <template v-if="hasAccountItems">
                <template v-for="(account, key) in accountItems" :key="key">
                  <tr
                    style="border-top: 1px solid #f1f1f1"
                    class="focus:outline-none h-16"
                  >
                    <td
                      v-if="isBulkReconnect || !isEasyConnectView"
                      class="text-center"
                    >
                      <CstSimpleCheckbox
                        v-if="isBulkReconnect"
                        v-model="selectedAccounts"
                        :name="account._id"
                        @change="onSelectSocialAccount"
                      ></CstSimpleCheckbox>
                      <CstSimpleCheckbox
                        v-if="!isEasyConnectView"
                        v-model="selectedAccounts"
                        :name="account._id"
                        @change="onSelectSocialAccount"
                      ></CstSimpleCheckbox>
                    </td>
                    <td class="pl-3">
                      <div class="flex items-center gap-2">
                        <div>
                          <img
                            v-if="type === 'Gmb'"
                            src="/img/google-business.png"
                            alt=""
                          />
                          <img
                            v-else
                            class="rounded-full w-10 h-10"
                            :src="platformImage(account, type)"
                            alt=""
                            @error="handleImageError"
                          />
                        </div>
                        <div class="overflow-hidden">
                          <p
                            v-if="isPinterestProfileListing"
                            v-tooltip="{
                              content: getPinterestProfileName(account),
                            }"
                            class="truncate text-[#3a4557] text-[0.875rem]"
                            >{{ getPinterestProfileName(account) }}
                          </p>
                          <div v-else class="flex items-center">
                            <p
                              v-tooltip="{ content: accountName(account) }"
                              class="truncate text-[#3a4557] text-[0.875rem]"
                            >
                              {{ accountName(account) }}
                            </p>
                            <i
                              v-if="
                                showAppInstallationWarning ||
                                isIGPersonalAccount(account)
                              "
                              v-tooltip="{
                                content: getInstagramWarningMessage(
                                  isIGPersonalAccount(account),
                                ),
                                allowHTML: true,
                              }"
                              class="fas fa-exclamation warning_icon"
                            ></i>
                            <img
                              v-if="
                                isTwitterListing &&
                                ['premium', 'premiumplus', 'basic'].includes(account?.subscription_type?.toLowerCase())

                              "
                              v-tooltip="'X (Twitter) Premium account'"
                              src="@src/assets/img/composer/premium-badge-icon.svg"
                              alt="premium badge"
                              class="ml-2"
                            />
                          </div>
                          <p class="text-left text-xs text-[#757A8A]">{{
                            accountType(account)
                          }}</p>
                        </div>
                      </div>
                    </td>
                    <td
                      v-if="isPinterestBoardListing && !isEasyConnectView"
                      class="text-center"
                      >{{ pinterestProfileName(account.profile_id) }}
                    </td>
                    <td
                      v-if="showQueueTime && !isEasyConnectView"
                      class="text-center"
                      >{{ getQueuedTimesCount(account) }}
                    </td>
                    <td v-if="!isEasyConnectView" class="text-center"
                      >{{
                        account?.connection_via_link
                          ? 'EasyConnect'
                          : appData.businessName
                      }}
                      <br />
                      <span class="text-xs text-[#757A8A]">{{
                        account?.connection_via_link
                          ? account?.external_link?.link_name
                          : account?.added_by
                      }}</span>
                    </td>
                    <td v-if="isTwitterListing" class="text-center">
                      {{
                        account?.developer_app?.app_name || appData.businessName
                      }}
                    </td>
                    <td
                      v-if="
                        isInstagramListing &&
                        canSaveSocialAccounts &&
                        !isEasyConnectView
                      "
                      class="text-center"
                    >
                      <div class="device_text">
                        <span @click="showAccountNotificationDevices(account)"
                          >{{ enableDevicesCount(account) }}/{{
                            getMobileDevices.length
                          }}
                          device(s)</span
                        >
                      </div>
                    </td>
                    <td v-if="showTokenStatus" class="text-center truncate">
                      <div
                        class="flex items-center gap-2 justify-center w-full status_list"
                      >
                        <div>
                          <div
                            v-tooltip.top="account.validity_error"
                            class="flex flex-col"
                            v-html="accountExpirationStates(account)"
                          >
                          </div>
                          <div
                            v-tooltip.top="limitExceededTooltip(account)"
                            class="status_items"
                          ></div>
                        </div>
                        <v-menu
                          v-if="
                            !isEasyConnectView && account?.connection_via_link
                          "
                          :popper-triggers="['hover']"
                          placement="top"
                          popper-class="first-comment__info-popover"
                          popover-inner-class="rounded-lg text-[979CA0] border p-2 !bg-white"
                          class="inline-block"
                        >
                          <i class="fa fa-info-circle p-0 cursor-pointer"></i>
                          <template v-slot:popper>
                            <p class="text-sm text-gray-900">
                              This social account was connected via EasyConnect.
                              Click Reconnect Link Button to copy the link and
                              share it with your client for reconnection. Or
                              <a
                                class="text-cs-primary cursor-pointer"
                                @click="
                                  copyConnectionLink(
                                    `${baseUrl}easy-connect/${account?.external_link?.link_url_slug}`,
                                  )
                                "
                              >
                                Click here to copy
                              </a>
                            </p>
                          </template>
                        </v-menu>
                      </div>
                    </td>
                    <td
                      v-if="canSaveSocialAccounts || isEasyConnectView"
                      class="text-center"
                    >
                      <div class="flex gap-2 justify-end">
                        <template
                          v-if="
                            isEasyConnectView || !account?.connection_via_link
                          "
                        >
                          <CstButton
                            v-if="
                              isFacebookListing &&
                              commonMethods.isFacebookGroup(account)
                            "
                            variant="outlined"
                            class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded !border hover:!border-gray-700 hover:!shadow-md !text-gray-700 box-shadow-secondary"
                            @click.prevent="editFbGroup(account)"
                          >
                            <div class="flex gap-2 items-center">
                              <i
                                class="icon-edit-cs text-[0.875rem] leading-none"
                                aria-hidden="true"
                              ></i>
                              <span
                                class="font-normal text-[0.875rem] leading-none"
                                >Edit</span
                              >
                            </div>
                          </CstButton>
                          <CstButton
                            v-else
                            variant="outlined"
                            class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded !border-[#ed7f317a] hover:!border-[#ED7F31] hover:!shadow-md !text-[#ED7F31] box-shadow-orange"
                            @click="onReconnectClick(account)"
                          >
                            <div class="flex gap-2 items-center">
                              <i
                                class="fa fa-refresh text-[0.875rem] leading-none"
                                aria-hidden="true"
                              ></i>
                              <span
                                class="font-normal text-[0.875rem] leading-none"
                                >Reconnect</span
                              >
                            </div>
                          </CstButton>
                        </template>
                        <template v-else>
                          <CstButton
                            v-tooltip.top="{
                              content:
                                'This social account was connected via EasyConnect. Click to copy the link and share it with your client for reconnection.',
                            }"
                            variant="outlined"
                            class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded !border-[#ed7f317a] hover:!border-[#ED7F31] hover:!shadow-md !text-[#ED7F31] box-shadow-orange"
                            @click="
                              copyConnectionLink(
                                `${baseUrl}easy-connect/${account?.external_link?.link_url_slug}`,
                              )
                            "
                          >
                            <div class="flex gap-2 items-center">
                              <i
                                class="fa fa-link text-[0.875rem] leading-none"
                                aria-hidden="true"
                              ></i>
                              <span
                                class="font-normal text-[0.875rem] leading-none"
                            >Reconnect Link</span
                            >
                          </div>
                        </CstButton>
                      </template>
                      <CstButton
                          v-if="!isPinterestProfileListing && !isEasyConnectView"
                          v-tooltip="queueAccess.allowed ? '' : queueAccess.error?.message"
                          variant="outlined"
                          class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded hidden 2xl:block"
                          :class="{
                            'hover:!border-gray-700 hover:!shadow-md !text-gray-700 box-shadow-secondary': queueAccess.allowed,
                            'opacity-70 !cursor-default': !queueAccess.allowed,
                          }"
                          @click.prevent="queueAccess.allowed ? fetchAccountQueueSlots(account) : showUpgradeModal()"
                      >
                        <div class="flex gap-2 items-center">
                          <i
                              class="icon-Calendar text-[0.875rem] leading-none"
                              aria-hidden="true"
                          ></i>
                          <span class="font-normal text-[0.875rem] leading-none"
                          >Queue Schedule</span
                          >
                        </div>
                      </CstButton>
                      <CstDropdown
                          v-if="!isEasyConnectView"
                          button-classes="flex h-[1.85rem] justify-between !px-3 !rounded !border !border-[#dee2e6] hover:!border-gray-700 hover:!shadow-md !text-gray-700 box-shadow-secondary"
                          container-classes="w-[9rem] border"
                          :show-arrow="false"
                        >
                          <template v-slot:selected>
                            <i
                              class="fa fa-ellipsis-v !mr-0 text-gray-700 text-sm leading-none"
                          ></i>
                        </template>
                        <template v-slot>
                          <CstDropdownItem
                              v-if="!isPinterestProfileListing && !isEasyConnectView"
                              v-tooltip="queueAccess.allowed ? '' : queueAccess.error?.message"
                              class="xl:hidden"
                              :class="{ 'cursor-pointer': queueAccess.allowed, '!text-gray-700 opacity-70 !cursor-default': !queueAccess.allowed }"
                              @click="queueAccess.allowed ? fetchAccountQueueSlots(account) : showUpgradeModal()"
                          >
                            <div class="flex items-center gap-2 !text-gray-700">
                              <i class="icon-Calendar" aria-hidden="true"></i>
                              <span class="!text-gray-700 text-[0.875rem] leading-none">Queue Schedule</span>
                            </div>
                          </CstDropdownItem>
                          <CstDropdownItem
                              v-if="!isEasyConnectView"
                              @click="shuffleQueue(accountConnectorId(account))"
                            >
                              <div
                                class="flex items-center gap-2 !text-gray-700"
                              >
                                <i class="fa fa-random" aria-hidden="true"></i>
                                <span
                                  class="!text-gray-700 text-[0.875rem] leading-none"
                              >Shuffle Posts</span
                              >
                            </div>
                          </CstDropdownItem>
                          <CstDropdownItem v-if="showLocation && !isEasyConnectView && isFacebookPage(account)" >
                            <div
                                class="flex items-center gap-2 !text-gray-700"
                            >
                              <i class="fa fa-location-dot" aria-hidden="true"></i>
                              <span
                                  class="!text-gray-700 text-[0.875rem] leading-none"
                              >Default Location</span
                              >
                            </div>
                          </CstDropdownItem>
                          <CstDropdownItem
                              v-if="account.policy && account.policy.can_remove"
                              class="account-selection-settings delete"
                              @click="removeAccount(account)"
                            >
                              <div class="flex items-center gap-2">
                                <i
                                  class="icon-delete-cs"
                                  aria-hidden="true"
                                ></i>
                                <span
                                  class="text-secondary-cs text-[0.875rem] leading-none"
                                  >Delete Account</span
                                >
                              </div>
                            </CstDropdownItem>
                          </template>
                        </CstDropdown>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
        <template
          v-if="!accountLoadingStatus && hasAccountItems && !isEasyConnectView"
        >
          <RecycleScroller
            v-slot="{ item: account }"
            class="scroller focus:outline-none h-16"
            :style="{ height: `${scrollerHeight}px`, 'min-height': '64px' }"
            style="border-top: 1px solid #f1f1f1"
            :items="accountItems"
            :item-size="64"
            key-field="_id"
          >
            <div class="table-row">
              <div
                v-if="isBulkReconnect || !isEasyConnectView"
                class="text-center"
              >
                <CstSimpleCheckbox
                  v-if="isBulkReconnect"
                  v-model="selectedAccounts"
                  :name="account._id"
                  @change="onSelectSocialAccount"
                ></CstSimpleCheckbox>
                <CstSimpleCheckbox
                  v-if="!isEasyConnectView"
                  v-model="selectedAccounts"
                  :name="account._id"
                  @change="onSelectSocialAccount"
                ></CstSimpleCheckbox>
              </div>
              <div class="pl-3">
                <div class="flex items-center gap-2">
                  <div>
                    <img
                      v-if="type === 'Gmb'"
                      src="/img/google-business.png"
                      alt=""
                    />
                    <img
                      v-else
                      class="rounded-full w-10 h-10"
                      :src="platformImage(account, type)"
                      alt=""
                      @error="handleImageError"
                    />
                  </div>
                  <div class="overflow-hidden">
                    <p
                      v-if="isPinterestProfileListing"
                      v-tooltip="{
                        content: getPinterestProfileName(account),
                      }"
                      class="truncate text-[#3a4557] text-[0.875rem]"
                      >{{ getPinterestProfileName(account) }}
                    </p>
                    <div v-else class="flex items-center">
                      <p
                        v-tooltip="{ content: accountName(account) }"
                        class="truncate text-[#3a4557] text-[0.875rem]"
                      >
                        {{ accountName(account) }}
                      </p>
                      <i
                        v-if="
                          showAppInstallationWarning ||
                          isIGPersonalAccount(account)
                        "
                        v-tooltip="{
                          content: getInstagramWarningMessage(
                            isIGPersonalAccount(account),
                          ),
                          allowHTML: true,
                        }"
                        class="fas fa-exclamation warning_icon"
                      ></i>

                      <img
                        v-if="
                          isTwitterListing &&
                          ['premium', 'premiumplus', 'basic'].includes(account?.subscription_type?.toLowerCase())

                        "
                        v-tooltip="'X (Twitter) Premium account'"
                        src="@src/assets/img/composer/premium-badge-icon.svg"
                        alt="premium badge"
                        class="ml-2"
                      />
                    </div>
                    <p class="text-left text-xs text-[#757A8A]">{{
                      accountType(account)
                    }}</p>
                  </div>
                </div>
              </div>
              <div
                v-if="isPinterestBoardListing && !isEasyConnectView"
                class="text-center"
                >{{ pinterestProfileName(account.profile_id) }}
              </div>
              <div v-if="showLocation && !isEasyConnectView" class="text-center truncate">
                <span
                    v-tooltip.top="account.location?.name"
                >{{ account?.location ? account?.location.name : '-' }}</span>
              </div>
              <div v-if="showQueueTime && !isEasyConnectView" class="text-center truncate"
              >{{ getQueuedTimesCount(account) }}
              </div>
              <div v-if="!isEasyConnectView" class="text-center"
                >{{
                  account?.connection_via_link
                    ? 'EasyConnect'
                    : appData.businessName
                }}
                <br />
                <span class="text-xs text-[#757A8A]">{{
                  account?.connection_via_link
                    ? account?.external_link?.link_name
                    : account?.added_by
                }}</span>
              </div>
              <div v-if="isTwitterListing" class="text-center">
                {{ account?.developer_app?.app_name || appData.businessName }}
              </div>
              <div
                v-if="
                  isInstagramListing &&
                  canSaveSocialAccounts &&
                  !isEasyConnectView
                "
                class="text-center"
              >
                <div class="device_text">
                  <span @click="showAccountNotificationDevices(account)"
                    >{{ enableDevicesCount(account) }}/{{
                      getMobileDevices.length
                    }}
                    device(s)</span
                  >
                </div>
              </div>
              <div v-if="showTokenStatus" class="text-center">
                <div
                  class="flex items-center gap-2 justify-center w-full status_list"
                >
                  <div>
                    <div
                      v-tooltip.top="account.validity_error"
                      class="flex flex-col"
                      v-html="accountExpirationStates(account)"
                    >
                    </div>
                    <div
                      v-tooltip.top="limitExceededTooltip(account)"
                      class="status_items"
                    ></div>
                  </div>
                  <v-menu
                    v-if="!isEasyConnectView && account?.connection_via_link"
                    :popper-triggers="['hover']"
                    placement="top"
                    popper-class="first-comment__info-popover"
                    popover-inner-class="rounded-lg text-[979CA0] border p-2 !bg-white"
                    class="inline-block"
                  >
                    <i class="fa fa-info-circle p-0 cursor-pointer"></i>
                    <template v-slot:popper>
                      <p class="text-sm text-gray-900">
                        This social account was connected via EasyConnect. Click
                        Reconnect Link Button to copy the link and share it with
                        your client for reconnection. Or
                        <a
                          class="text-cs-primary cursor-pointer"
                          @click="
                            copyConnectionLink(
                              `${baseUrl}easy-connect/${account?.external_link?.link_url_slug}`,
                            )
                          "
                        >
                          Click here to copy
                        </a>
                      </p>
                    </template>
                  </v-menu>
                </div>
              </div>
              <div
                v-if="canSaveSocialAccounts || isEasyConnectView"
                class="text-center"
              >
                <div class="flex gap-2 justify-end">
                  <template
                    v-if="isEasyConnectView || !account?.connection_via_link"
                  >
                    <CstButton
                      v-if="
                        isFacebookListing &&
                        commonMethods.isFacebookGroup(account)
                      "
                      variant="outlined"
                      class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded !border hover:!border-gray-700 hover:!shadow-md !text-gray-700 box-shadow-secondary"
                      @click.prevent="editFbGroup(account)"
                    >
                      <div class="flex gap-2 items-center">
                        <i
                          class="icon-edit-cs text-[0.875rem] leading-none"
                          aria-hidden="true"
                        ></i>
                        <span class="font-normal text-[0.875rem] leading-none"
                          >Edit</span
                        >
                      </div>
                    </CstButton>
                    <CstButton
                        v-else-if="isBlueskyListing"
                        variant="outlined"
                        class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded !border-[#ed7f317a] hover:!border-[#ED7F31] hover:!shadow-md !text-[#ED7F31] box-shadow-orange"
                        @click="onReconnectClick(account)"
                    >
                      <div class="flex gap-2 items-center">
                        <i
                            class="fa fa-refresh text-[0.875rem] leading-none"
                            aria-hidden="true"
                        ></i>
                        <span class="font-normal text-[0.875rem] leading-none"
                        >Reconnect</span
                        >
                      </div>
                    </CstButton>
                    <CstButton
                      v-else
                      variant="outlined"
                      class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded !border-[#ed7f317a] hover:!border-[#ED7F31] hover:!shadow-md !text-[#ED7F31] box-shadow-orange"
                      @click="onReconnectClick(account)"
                    >
                      <div class="flex gap-2 items-center">
                        <i
                          class="fa fa-refresh text-[0.875rem] leading-none"
                          aria-hidden="true"
                        ></i>
                        <span class="font-normal text-[0.875rem] leading-none"
                          >Reconnect</span
                        >
                      </div>
                    </CstButton>
                  </template>
                  <template v-else>
                    <CstButton
                      v-tooltip.top="{
                        content:
                          'This social account was connected via EasyConnect. Click to copy the link and share it with your client for reconnection.',
                      }"
                      variant="outlined"
                      class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded !border-[#ed7f317a] hover:!border-[#ED7F31] hover:!shadow-md !text-[#ED7F31] box-shadow-orange"
                      @click="
                        copyConnectionLink(
                          `${baseUrl}easy-connect/${account?.external_link?.link_url_slug}`,
                        )
                      "
                    >
                      <div class="flex gap-2 items-center">
                        <i
                          class="fa fa-link text-[0.875rem] leading-none"
                          aria-hidden="true"
                        ></i>
                        <span class="font-normal text-[0.875rem] leading-none"
                          >Reconnect Link</span
                        >
                      </div>
                    </CstButton>
                  </template>
                  <CstButton
                      v-if="!isPinterestProfileListing && !isEasyConnectView"
                      v-tooltip="queueAccess.allowed ? '' : queueAccess.error?.message"
                      variant="outlined"
                      class="!px-3 !py-[0.438rem] h-[1.85rem] !rounded hidden 2xl:block"
                      :class="{
                            'hover:!border-gray-700 hover:!shadow-md !text-gray-700 box-shadow-secondary': queueAccess.allowed,
                            '!text-gray-700 opacity-70 !cursor-default': !queueAccess.allowed,
                          }"
                      @click.prevent="queueAccess.allowed ? fetchAccountQueueSlots(account) : showUpgradeModal()"
                  >
                    <div class="flex gap-2 items-center">
                      <i
                        class="icon-Calendar text-[0.875rem] leading-none"
                        aria-hidden="true"
                      ></i>
                      <span class="font-normal text-[0.875rem] leading-none"
                        >Queue Schedule</span
                      >
                    </div>
                  </CstButton>

                  <!--  Action Dropdown starts-->
                  <div class="relative inline-block text-left">
                    <button
                      ref="dropdownButton"
                      type="button"
                      class="flex h-[1.85rem] bg-white justify-between items-center px-3 rounded border text-gray-700 box-shadow-secondary"
                      @click="toggleDropdown(account._id, $event)"
                    >
                      <i
                        class="fa fa-ellipsis-v text-gray-700 text-sm leading-none"
                      ></i>
                    </button>
                    <teleport to="body">
                      <div
                        v-if="openDropdowns[account._id]"
                        ref="dropdownMenu"
                        v-click-away="() => clickAway(account._id)"
                        :style="dropdownStyle[account._id]"
                        class="origin-top-right absolute right-0 mt-2 w-40 rounded-xl bg-white border focus:outline-none z-[9999]"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        tabindex="-1"
                      >
                        <div class="py-1" role="none">
                          <a
                              v-if="!isPinterestProfileListing && !isEasyConnectView"
                              v-tooltip="queueAccess.allowed ? '' : queueAccess.error?.message"
                              class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 2xl:hidden"
                              :class="{
                                'cursor-pointer': queueAccess.allowed,
                                'opacity-70 cursor-default': !queueAccess.allowed,
                              }"
                              @click.prevent="queueAccess.allowed ? fetchAccountQueueSlots(account) : showUpgradeModal()"
                          >
                            <div class="flex items-center gap-2 !text-gray-700">
                              <i class="icon-Calendar" aria-hidden="true"></i>
                              <span
                                  class="!text-gray-700 text-[0.875rem] leading-none"
                              >Queue Schedule</span
                              >
                            </div>
                          </a>
                          <a
                            href="#"
                            class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                            role="menuitem"
                            tabindex="-1"
                            @click.prevent="
                              shuffleQueue(accountConnectorId(account))
                            "
                          >
                            <div class="flex items-center gap-2 !text-gray-700">
                              <i class="fa fa-random" aria-hidden="true"></i>
                              <span
                                class="!text-gray-700 text-[0.875rem] leading-none"
                                >Shuffle Posts</span
                              >
                            </div>
                          </a>
                          <a
v-if="showLocation && !isEasyConnectView && isFacebookPage(account)" href="#"
                             class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabindex="-1"
                             @click.prevent="handleLocation(account)">
                            <div class="flex items-center gap-2 !text-gray-700">
                              <i class="fa fa-map-marker" aria-hidden="true"></i>
                              <span
                                  class="!text-gray-700 text-[0.875rem] leading-none"
                              >Default Location</span
                              >
                            </div>
                          </a>
                          <a
                            v-if="account.policy && account.policy.can_remove"
                            href="#"
                            class="text-red-600 block px-4 py-2 text-sm hover:bg-gray-100 account-selection-settings delete"
                            role="menuitem"
                            tabindex="-1"
                            @click.prevent="removeAccount(account)"
                          >
                            <div class="flex items-center gap-2">
                              <i class="icon-delete-cs" aria-hidden="true"></i>
                              <span
                                class="text-secondary-cs text-[0.875rem] leading-none"
                                >Delete Account</span
                              >
                            </div>
                          </a>
                        </div>
                      </div>
                    </teleport>
                  </div>
                  <!--  Action Dropdown End-->
                </div>
              </div>
            </div>
          </RecycleScroller>
        </template>
      </div>
      <div
        v-if="!hasAccountItems && search.length > 0 && !searchLoader"
        class="flex flex-col items-center justify-center py-4 mb-2"
      >
        <p class="text-base"
          >No accounts found for "{{ search }}". Please try a different search
          term.</p
        >
      </div>
      <div
        v-else-if="!hasAccountItems && !accountLoadingStatus"
        class="flex flex-col items-center justify-center py-4 mb-2"
      >
        <p v-if="canSaveSocialAccounts" class="text-base">
          You have not connected any account yet, click on the button to get
          started.</p
        >
        <p v-else class="text-base">You have not connected any account.</p>
        <div v-if="isInstagramListing && !isEasyConnectView">
          <a
            href="https://docs.contentstudio.io/article/722-how-to-connect-instagram-business-account-with-contentstudio"
            target="_blank" rel="noopener"
            class="insta-link"
            >Learn how to connect Instagram with {{ appData.businessName }}.
          </a>
        </div>
        <div v-if="isPinterestListing && !isEasyConnectView">
          <a
            href="https://docs.contentstudio.io/article/833-how-to-connect-pinterest-boards-accounts-with-contentstudio"
            target="_blank" rel="noopener"
            class="insta-link"
            >Learn how to connect Pinterest with {{ appData.businessName }}.
          </a>
        </div>
      </div>
      <div v-if="isInstagramListing && !isEasyConnectView" class="py-4 px-4">
        <span class="text-base font-normal">
          Want to learn everything from connection of Instagram business account
          to posting?</span
        >
        <a
          href="https://docs.contentstudio.io/article/729-getting-started-with-instagram-publishing"
          target="_blank" rel="noopener"
          class="text-base"
        >
          Read through our help document.</a
        >
      </div>
      <div v-if="isPinterestListing && !isEasyConnectView" class="py-4 px-4">
        <span class="text-base font-normal"
          >Want to learn everything from connection of Pinterest account to
          posting?</span
        >
        <a
          href="https://docs.contentstudio.io/article/842-getting-started-with-pinterest-publishing"
          target="_blank" rel="noopener"
          class="text-base"
        >
          Read through our help document.</a
        >
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.scroller {
  max-height: 600px; /* Adjust this value based on your desired height */
  overflow-y: auto;
}

.table-row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid #f1f1f1;
}

.table-row > div {
  display: table-cell;
  vertical-align: middle;
  padding: 8px;
  height: 64px; /* Match this with your item-size */
}

/* Match these widths with your table headers */
.table-row > div:nth-child(1) {
  width: 3.125rem;
}

.table-row > div:nth-child(2) {
  width: 26%;
}

/* Add more selectors for other columns as needed */

/* Specific styles for the last column (Actions) */
.table-row > div:last-child {
  width: 11.375rem;
}

@media (min-width: 1280px) {
  /* xl breakpoint */
  .table-row > div:last-child {
    width: 22rem;
  }
}

.table-row > div {
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Adjust the scroller to remove default padding */
.vue-recycle-scroller__item-wrapper {
  padding: 0 !important;
}

.disableEvents {
  background: linear-gradient(to right, #f43838, #e02f5f);
  box-shadow: 0 1px 0 0 rgba(152, 158, 181, 0.3);
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.875rem;
  width: 635px;
  cursor: pointer;
}

.action-item-custom {
  padding: 0.6rem;
  margin: 0.1rem;
  font-size: 0.8rem;
  border-radius: 7px;
}

@media only screen and (max-width: 1350px) {
  .action-item-custom {
    padding: 0.4rem;
    margin-left: 0.15rem;
    font-size: 0.77rem;
    border-radius: 4px;
  }
}

.text-inherit {
  color: inherit;
}
</style>

<style lang="scss">
.account-selection-settings.delete {
  span {
    color: #f43838 !important;
  }

  &:hover {
    background-color: #f43838 !important;

    span {
      color: white !important;
    }

    i {
      color: white !important;
    }
  }

  i {
    color: #f43838 !important;
  }
}

.box-shadow-secondary {
  &:active {
    -webkit-box-shadow: 0 0 0 0.1rem rgba(60, 69, 73, 0.5) !important;
    box-shadow: 0 0 0 0.1rem rgba(60, 69, 73, 0.5) !important;
    border-color: rgba(60, 69, 73, 0.5) !important;
  }
}

.box-shadow-orange {
  &:active {
    -webkit-box-shadow: 0 0 0 0.1rem rgba(237, 127, 49, 0.5) !important;
    box-shadow: 0 0 0 0.1rem rgba(237, 127, 49, 0.5) !important;
    border-color: rgba(237, 127, 49) !important;
  }
}
</style>
