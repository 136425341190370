<template>
  <div>
    <slot
      name="child"
      :type="props.type"
      :plan-id="props.planId"
      :modal-toggler="state.modalToggler"
      :saved-prompts-toggler="state.savedPromptsToggler"
      :history-toggler="state.historyToggler"
      :active-chat-loader="state.activeChatLoader"
      :active-chat="state.activeChat"
      :chats="state.chats"
      :message-loader="state.messageLoader"
      :prompt-input="state.promptInput"
      :is-sidebar-expanded="props.isSidebarExpanded"
      :toggle-sidebar-size="props.toggleSidebarSize"
      :fetch-active-chat="fetchActiveChat"
      :send-chat-message="sendChatMessageStream"
      :delete-chat-message="deleteMessage"
      :toggle-chat-dialogs="toggleChatDialogs"
      :handle-custom-prompts="handleCustomPrompts"
      :handle-new-chat="handleNewChat"
      :fetch-chat-by-id="fetchChatById"
      :images="state.activeChat.messages.images"
    >
    </slot>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import proxy from '@common/lib/http-common'
import {
  conversationSend,
  fetchAIChatsUrl,
  fetchChatByIdUrl,
  deleteChatMessage, conversationSendWithStream,
} from '@src/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { useStore } from '@state/base'
import { reactive, onMounted, watch } from 'vue'
import { commonMethods } from '@common/store/common-methods'
import { EventBus } from '@common/lib/event-bus'
import moment from "moment";
import {fetchEventSource} from "@fortaine/fetch-event-source";
import {userMavenMixin} from "@src/tracking/userMavenMixin";
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";
const store = useStore()

const props = defineProps({
  type: {
    type: String,
    default: 'modal',// modal or blog
  },
  planId: {
    type: String,
    default: null,
  },
  sidebarStatus: {
    type: Boolean,
    default: false,
  },
  toggleSidebarSize: {
    type: Function,
    default: () => {},
  },
  isSidebarExpanded: {
    type: Boolean,
    default: false,
  },
})
const state = reactive({
  modalToggler: false,
  savedPromptsToggler: false,
  historyToggler: false,
  activeChatLoader: true,
  messageLoader: false,
  activeChat: {
    id: null,
    messages: [],
  },
  chats: [],
  promptInput: '',
  controller: null,
})

const {isComposerModalOpen, showNewChat} = useComposerHelper()

onMounted(() => {
  if (props.type === 'blog') {
    state.modalToggler = props.sidebarStatus
  }
})

const toggleChatDialogs = (type = 'chatModalOpen') => {
  switch (type) {
    case 'openSavedPrompts':
      state.historyToggler = false
      state.savedPromptsToggler = !state.savedPromptsToggler
      break
    case 'openHistory':
      state.savedPromptsToggler = false
      state.historyToggler = !state.historyToggler
      break
    case 'openChatModal':
      preventScroll(true)
      state.modalToggler = true
      break
    case 'closeHistory':
      state.historyToggler = false
      break;
    case 'closeChatModal':
      preventScroll(false)
      state.modalToggler = false
      state.savedPromptsToggler = false
      state.historyToggler = false
      showNewChat.value = false
      break
    case 'closeHelper':
      state.savedPromptsToggler = false
      state.historyToggler = false
      break;
  }
}
const handleCustomPrompts = (value, type) => {
  switch (type) {
    case 'append':
      state.promptInput = value.prompt ?? value
      EventBus.$emit('append-prompt', value)
      // state.savedPromptsToggler = false
      break
    case 'save':
      state.savedPromptsToggler = true
      EventBus.$emit('save-user-prompt', value)
      break
  }
}

const handleNewChat = () => {
  if(state.controller) state.controller?.abort()
  const existingChat = state.chats?.find((item) => item?._id === state.activeChat.id)
  if (!existingChat) {
    const { messages } = state.activeChat;
    const user = messages?.find(item => item.role === 'user') || null;
    const assistant = messages?.find(item => item.role === 'assistant') || null;

    const chat = {
      _id: state.activeChat.id,
      blog_id: props.planId,
      first_message: {
        user,
        assistant,
      },
      is_active: false,
      created_at: moment().utc(),
    }
    state.chats.unshift(chat)
  }
  state.activeChatLoader = true
  state.activeChat.id = null
  state.activeChat.messages = []
  state.promptInput = ''
  state.messageLoader = false
  state.activeChatLoader = false
}
const preventScroll = (val = false) => {
  const body = document.body
  const ele = document.getElementById('AI_chat_widget')
  if (val) {
    // to prevent body scroll uncomment below lines
    // body.classList.add('overflow-hidden')
    // body.classList.add('pr-3.5')
    commonMethods.toggleHelpDropdown(true)
    if (ele) ele.classList.add('hidden')
    return
  }
    // body.classList.remove('overflow-hidden')
    // body.classList.remove('pr-3.5')
    commonMethods.toggleHelpDropdown(false)
    if (ele && !isComposerModalOpen.value) ele.classList.remove('hidden')
}
const fetchActiveChat = async () => {
  if (!state.messageLoader) {
    try {
      const response = await proxy.get(
        `${fetchAIChatsUrl}?workspace_id=${store.getters.getWorkspaces.activeWorkspace._id}` + (props.planId ? `&blog_id=${props.planId}` : '')
      )
      // handle null
      if (response.data.status) {
        state.activeChatLoader = false
        state.activeChat.id = response.data.active_chat ? response.data.active_chat._id : null
        state.activeChat.messages = response.data.active_chat ? response.data.active_chat.messages : []
      }
      state.chats = response.data.chats || []
      state.activeChatLoader = false
    } catch (err) {
      console.debug('ErrorfetchActiveChat', err)
      state.activeChatLoader = false
      await store.dispatch('toastNotification', {
        message: err.response?.data?.message || UNKNOWN_ERROR,
        type: 'error',
      })
    }
  }
}

const fetchChatById = async (chatId) => {
  state.activeChatLoader = true
  try {
    const response = await proxy.get(
      `${fetchChatByIdUrl}?workspace_id=${store.getters.getWorkspaces.activeWorkspace._id}&chat_id=${chatId}`
    )
    if (response.data.status) {
      state.activeChatLoader = false
      state.activeChat.id = response.data.chat._id
      state.activeChat.messages = response.data.chat.messages
    }
    state.activeChatLoader = false
  } catch (err) {
    console.debug('ErrorfetchActiveChatById', err)
    state.activeChatLoader = false
    await store.dispatch('toastNotification', {
      message: err.response?.data?.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }
}
const sendChatMessage = (value, images = []) => {
  const payload = {
    workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
    content: value,
    chat_id: state.activeChat.id,
    images: images // Add base64 images to payload
  }

  if(props.planId){
    payload.blog_id = props.planId
  }

  // Add message to UI immediately with images
  state.activeChat.messages.push({
    content: [
      { type: 'text', text: value },
      ...(images.length > 0 ? images.map(img => ({ type: 'image_url', image_url: { url: img } })) : [])
    ],
    role: 'user',
    updated_at: moment().format(),
    created_at: moment().format()
  })

  state.messageLoader = true

  state.controller = new AbortController();
  state.signal = state.controller?.signal;

  proxy.post(conversationSend, payload, { signal:state.signal })
    .then((response) => {
      state.messageLoader = false
      if(response.data.chat_id)
        state.activeChat.id = response.data.chat_id
      if (response.data.status) {
        state.activeChat.messages.push(response.data.chat_message)
        if(response.data.limits.used) store.getters.getPlan.used_limits.caption_generation_credit = response.data.limits.used
      } else {
        store.dispatch('toastNotification', {
          message: response.data?.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    })
    .catch((error) => {
      if(error.code === "ERR_CANCELED") return
      state.messageLoader = false
      store.dispatch('toastNotification', {
        message: error.response?.data?.message || UNKNOWN_ERROR,
        type: 'error',
      })
    }).finally(() => {
      state.controller = null;
    })
}

const sendChatMessageStream = (value, images = []) => {
  userMavenMixin?.methods?.trackUserMaven('ai-chat', {
    action: 'send-message',
    chatType: props.type,
  })

  const payload = {
    workspace_id: store.getters.getWorkspaces.activeWorkspace._id,
    content: value,
    chat_id: state.activeChat.id,
    images: images // Add base64 images to payload
  }

  if(props.planId){
    payload.blog_id = props.planId
  }

  // Add message to UI immediately with images
  state.activeChat.messages.push({
    content: [
      { type: 'text', text: value },
      ...(images.length > 0 ? images.map(img => ({ type: 'image_url', image_url: { url: img } })) : [])
    ],
    role: 'user',
    updated_at: moment().format(),
    created_at: moment().format()
  })

  state.controller = new AbortController();
  state.signal = state.controller?.signal;

  // stream message using fetch-event-source package
  fetchEventSource(conversationSendWithStream, {
    signal: state.signal,
    openWhenHidden: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${store.getters.getJWTToken}`
    },
    body: JSON.stringify(payload),
    onopen: (response) => {
      if(response.ok) {
        state.messageLoader = true
        state.activeChat.messages.push({
          _id: "",
          content: "",
          role: 'assistant',
          images: [] // Initialize empty images array for assistant response
        })
      } else {
        if(state.controller)
          state.controller?.abort()
        state.messageLoader = false
        store.dispatch('toastNotification', {
          message: response?.data?.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    },
    onmessage: (event) => {
      if(event.data) {
        let data = JSON.parse(event.data)
        if (data.message) {
          state.activeChat.messages[state.activeChat.messages.length - 1].content += data.message
        } else if (data.data) {
          let response = data.data
          if (response.chat_id)
            state.activeChat.id = response.chat_id
          if (response.error) {
            store.dispatch('toastNotification', {
              message: response.error,
              type: 'error',
            })
            if (state.activeChat.messages[state.activeChat.messages.length - 1].content === "")
              state.activeChat.messages.pop()
          }
          if (response.limits?.used)
            store.getters.getPlan.used_limits.caption_generation_credit = response.limits.used
          if (response.chat_message) {
            // Preserve any existing images in the message
            const existingImages = state.activeChat.messages[state.activeChat.messages.length - 1].images || []
            state.activeChat.messages[state.activeChat.messages.length - 1] = {
              ...response.chat_message,
              images: response.chat_message.images || existingImages
            }
          }
        }
      }
    },
    onclose: () => {
      state.messageLoader = false
      state.controller = null;
      throw new Error(); // hack to close the connection
    },
    onerror: (err) => {
      if(state.controller) {
        state.controller?.abort()
      }
      state.controller = null;
      state.messageLoader = false
      throw new Error(); // hack to close the connection (infinite loop issue if we don't throw error)
    },
  });
}

const deleteMessage = async (value) =>  {
  if(value){
    const response = await proxy.delete(`${deleteChatMessage}?workspace_id=${store.getters.getWorkspaces.activeWorkspace._id}&message_id=${value}`)
    if(response.data.status && response.data.result){
      state.activeChat.messages = state.activeChat.messages.filter((item) => item._id !== value)
      store.dispatch('toastNotification', {
        message: 'Message deleted successfully!',
        type: 'info',
      })
      return
    }
    store.dispatch('toastNotification', {
      message: UNKNOWN_ERROR,
      type: 'error',
    })
  }
}

// toggle prompt and history dialog with blog sidebar
watch(
  () => props.sidebarStatus,
  (value) => {
    if (!value) {
      state.savedPromptsToggler = false
      state.historyToggler = false
    }
  }
)
</script>
