<template>
  <div>
    <div class="media-tab__header"> Add Media from Google Drive </div>
    <div class="media-tab__body">
      <div v-if="pickedImage.length > 0" class="media-tab__info">
        <div class="media-tab__count">
          Item{{ pickedImage.length > 1 ? 's' : '' }} of size
          {{ bytesToSize(totalSize) }}
          {{ pickedImage.length > 1 ? 'are' : 'is' }} ready to upload.</div
        >
        <div class="media-tab__actions">
          <button
            :disabled="isUploading"
            href="javascript:;"
            @click="clearFiles"
            >Remove All</button
          >
        </div>
      </div>


      <div v-if="pickedImage.length > 0" class="media-tab__items">
        <div class="grid-flex">
          <div class="cell-md-3">
            <label class="media-tab__upload-btn" @click="driveIconClicked">
              <div>
                <i class="fab fa-google-drive"></i>
                Add From Drive
              </div>
            </label>
          </div>
          <div
            v-for="(image, k) in pickedImage"
            :key="k"
            class="cell-md-3 mb-3"
          >
            <Asset
              :id="image._id"
              :hide-delete="isUploading"
              type="secondary"
              :alt="image.name"
              :src="image.url"
              :info="image"
              @delete="deleteItem"
            />
          </div>
          <div
            v-if="fetchingDriveData"
            class="cell-md-3 mb-3 text-center my-auto"
          >
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
      <div
        v-else-if="pickedImage.length == 0 && !fetchingDriveData"
        class="
          media-tab__items
          align-items-center
          justify-content-center
          d-flex
          flex-column
        "
        style="height: 533px"
      >
        <img
          draggable="false"
          src="../assets/img/google_drive.png"
          alt=""
          width="250"
        />

        <div class="my-3 cursor-pointer" @click="driveIconClicked">
          <img
            src="@assets/img/btn_google_signin.png"
            alt="Sign in button"
            width="200"
          />
        </div>
      </div>
      <div
        v-else
        class="
          media-tab__items
          align-items-center
          justify-content-center
          d-flex
          flex-column
        "
        style="height: 533px"
      >
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>

    <div v-if="pickedImage.length > 0" class="media-tab__footer">
      <div class="d-flex align_center">
        <div class="mr-3 font-0-95rem">Folder:</div>
        <div class="media-tab__footer-folders">
          <i class="far fa-folder mr-3"></i>
          <TreeSelect
            v-model="selectedFolderValue"
            :disabled="isUploading"
            placeholder="Select Folder"
            :clearable="false"
            :flatten-search-results="true"
            :options="treeFolders"
            :normalizer="normalizer"
          >
          </TreeSelect>
        </div>
      </div>

      <div
        class="
          media-tab__footer-actions
          d-flex
          flex-grow-1
          justify-content-end
          flex-shrink-0
          items-center
          gap-x-8
        "
      >
          <div v-if="isUploading">
            <b-progress
            v-if="progress"
            class="w-60 2xl:w-96"
            :value="progress"
            :max="100"
            show-progress
            animated
        >
          <b-progress-bar :value="progress" >
            {{ progress }}%
          </b-progress-bar>
        </b-progress>
            <span v-else><i>Preparing files to upload...</i></span>
          </div>

         <div class="flex items-center gap-x-4">

          <CstButton
          v-tooltip="'Upload to Media Library'"
          variant="secondary"
          :disabled="isUploading || fetchingDriveData"
          @click="uploadFiles(false)"
        >
          Upload
          <clip-loader
            v-if="isUploading"
            class="spinner ml-2"
            :color="'#e0dfdf'"
            :size="'16px'"
          ></clip-loader>
        </CstButton>

          <CstButton
          v-tooltip="'Add to Composer and Upload to Media Library'"
          class="btn btn-studio-theme-space"
          :disabled="isUploading || fetchingDriveData"
          @click="uploadFiles(true)"
        >
          Add to Composer
          <clip-loader
            v-if="isUploading"
            class="spinner ml-2"
            :color="'#e0dfdf'"
            :size="'16px'"
          ></clip-loader>
        </CstButton>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import Asset from '@src/modules/publish/components/media-library/components/Asset'
import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import CstButton from '@ui/Button/CstButton.vue';

export default {
  name: 'GoogleDriveAuth',
  components: {
    Asset,
    CstButton
  },
  mixins: [MediaHelperMixin],
  props: ['type', 'folders'],
  emits: ['changeTab'],
  data() {
    return {
      // Google Picker Properties.
      pickerApiLoaded: false,
      developerKey: process.env.VUE_APP_GOOGLE_API_KEY, // The API Key obtained from the Google API Console. Replace with your own API Key.
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID, // The OAuth Client ID obtained from the Google API Console. Replace with your own OAuth Client ID.
      scope: 'https://www.googleapis.com/auth/drive', // Scope to use to access user's Drive items.
      oauthToken: null,
      pickedImage: [],
      fetchingDriveData: false,

      // Footer properties.
      isUploading: false,
      selectedFolderValue: 'uncategorized',

      // ~~~~
      progress: null,
    }
  },
  computed: {
    treeFolders() {
      let list = [{ id: 'uncategorized', label: 'Uncategorized' }]

      list = [
        ...list,
        ...this.folders.map((folder) => {
          const temp = folder

          temp.children = folder.sub_folders.map((subfolder) => {
            return {
              id: subfolder._id,
              label: subfolder.folder_name,
            }
          })

          if (temp.children.length === 0) {
            return {
              id: temp._id + '-root',
              label: temp.folder_name,
            }
          }

          return {
            id: temp._id + '-root',
            label: temp.folder_name,
            children: temp.children,
          }
        }),
      ]

      return list
    },
    totalSize() {
      let size = 0

      this.pickedImage.forEach((image) => {
        size = size + image.size
      })

      return size
    },
  },
  watch: {
    isUploading(val) {
      this.$emit('uploading', val)
    },
  },
  created() {
    this.init()
    console.log('Created Google Drive Component.')
  },
  methods: {
    // Injecting google picker api sdk hook.
    init() {
      // if google picker sdk already exist, don't create new one.

      if (!document.getElementById('googlepickersdk')) {
        console.log('Here is the picker sdk')
        const gDrive = document.createElement('script')
        gDrive.setAttribute('id', 'googlepickersdk')
        gDrive.setAttribute('type', 'text/javascript')
        gDrive.setAttribute('src', 'https://apis.google.com/js/api.js')
        // gDrive.src = 'https://apis.google.com/js/api.js?onload=loadPicker';
        document.head.appendChild(gDrive)
      }
    },

    // Handling Google Auth and Loading the Picker.
    async driveIconClicked() {
      console.log('Clicked')

      await gapi.load('auth2', () => {
        console.log('Auth2 Loaded')
        gapi.auth2.authorize(
          {
            client_id: this.clientId,
            scope: this.scope,
            prompt: 'select_account',
            immediate: false,
          },
          this.handleAuthResult
        )
      })
      gapi.load('picker', () => {
        console.log('Picker Loaded')
        this.pickerApiLoaded = true
        // this.createPicker();
      })
    },

    // Handling Auth Callback (managing access Token)
    handleAuthResult(authResult) {
      console.log('Handle Auth result', authResult)
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token
        this.pickerApiLoaded = true
        this.createPicker()
      }
    },

    // Creating Google Picker.
    createPicker() {
      console.log('Create Picker', google.picker)
      const view = new google.picker.View(
        google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS
      )
      // view.setMimeTypes("image/png,image/jpeg,image/jpg");
      if (this.pickerApiLoaded && this.oauthToken) {
        window.picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .addView(view)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(this.developerKey)
          .setCallback(this.pickerCallback)
          .build()
        picker.setVisible(true)

        // Show the picker dialog above the bootstrap modal.
        const elements = document.getElementsByClassName('picker-dialog')
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.zIndex = '2000'
        }
      }
    },

    // Google Picker callback. (When the picker is ready or when the user selects an image.)
    async pickerCallback(data) {
      const self = this
      console.log('PickerCallback', data)

      // console.log("JAYDEE --> ", google.picker.Document.ID);
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        const doc = data[google.picker.Response.DOCUMENTS] // Returns array of items picked.

        // Download the image via Google drive api
        self.downloadGDriveImage(doc)
      }
    },

    // Downloading the image via google drive api.
    async downloadGDriveImage(doc) {
      const self = this
      let image = null
      self.fetchingDriveData = true
      console.log('Fetching Drive Data -----> ', self.fetchingDriveData)

      // Using the access token to make the api call to google drive to fetch image. Note: the google drive api should be enabled from library in order to make the call.
      for (var i = 0; i < doc.length; i++) {
        image = doc[i]
        await fetch(
            'https://www.googleapis.com/drive/v3/files/' +
              image.id +
              '?alt=media',
            {
              headers: { Authorization: `Bearer ${self.oauthToken}` },
              method: 'GET',
            }
          )
          .then(response => response.blob())
          .then((blob) => {
            console.log('DOC ----> ', image)
            const prepImgObj = {
              _id: self.pickedImage.length + 1,
              name: image.name,
              size: image.sizeBytes,
              type: image.mimeType,
              url: URL.createObjectURL(blob),
              file: new File([blob], image.name, { type: image.mimeType }),
            }
            console.log(prepImgObj)
            self.pickedImage.push(prepImgObj)
            // self.fetchingDriveData = false;
            console.log(
              'Fetching Drive Data -----> ',
              self.fetchingDriveData,
              i
            )
          })
          .catch((err) => {
            // self.fetchingDriveData = false;
            console.log(
              'Fetching Drive Data -----> ',
              self.fetchingDriveData,
              i
            )
            console.log(err)
          })
      }
      self.fetchingDriveData = false
      console.log('Fetching Drive Data -----> ', self.fetchingDriveData)
      console.log('Image -->', self.pickedImage)
    },

    normalizer(node) {
      let name = node.label

      if (node.id === 'uncategorized') {
        name = name + ' (Main)'
      }

      return {
        id: node.id,
        label: name,
        children: node.children,
      }
    },

    deleteItem(id) {
      this.pickedImage = this.pickedImage.filter((img) => img._id !== id)
    },

    async uploadFiles(addToComposer) {
      this.isUploading = true

      let folderId = null
      let isRoot = false

      const filters = {
        media: this.pickedImage,
        folder_id: null,
      }

      // If any folder selected
      if (!isEmpty(this.selectedFolderValue)) {
        ;[folderId, isRoot] = this.selectedFolderValue.split('-')

        folderId = folderId === 'uncategorized' ? null : folderId
        isRoot = !isEmpty(isRoot)
        filters.folder_id = folderId

        if (folderId) {
          filters.is_root = isRoot
        }
      }

      await this.uploadFilesHelper(
        filters,
        (status, message, mediaContainer) => {
          if (status) {
            if(addToComposer){
              EventBus.$emit('add-media-to-editor', mediaContainer)
            }
            this.clearFiles()
            this.isUploading = false
            if (this.type === 'library') {
              this.$bvModal.hide('upload-media-modal')
              EventBus.$emit('refetch-folders')
              EventBus.$emit('refetch-media', { folderId, isRoot })
              EventBus.$emit('refetch-media-limits')
            }
            else {
              if(addToComposer){
                this.$bvModal.hide('upload-media-modal')
                return
              }
              this.$emit('changeTab', 1)
            }
          }
        },
        (percentCompleted) => {
          this.progress = percentCompleted
        }
      )
      this.isUploading = false
    },
    clearFiles() {
      this.pickedImage = []
    },
  },
}
</script>

<style scoped></style>
